import { Suspense, lazy } from "react"
import { Navigate } from "react-router-dom"


const boardRouter = () => {
    const Loading = <div>Loading....</div>
    const BoardList = lazy(() => import("../pages/board/ListPage"))

    return [
        {
            path:"list",
            element:<Suspense fallback={Loading}><BoardList/></Suspense>
        },
        {
            path:"",
            element:<Navigate replace to="/board/list"/>
        }
    ]
}

export default boardRouter;