import { Suspense, lazy } from "react"
import { Navigate } from "react-router-dom"


const chartRouter = () => {
    // const Loading = <div>Loading....</div>
    const Loading = 
        <div className={`fixed top-0 left-0 z-[1055] flex h-full w-full place-items-center justify-center bg-black bg-opacity-20`}>
            <div className=" bg-white rounded-3xl opacity-100 h-1/4 min-w-[600px] flex justify-center items-center ">
                <div className="text-4xl font-extrabold text-orange-400 m-20">
                    Loading.....
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    const ChartList = lazy(() => import("../pages/chart/ChartPage"))

    return [
        {
            path:"list",
            element:<Suspense fallback={Loading}><ChartList/></Suspense>
        },
        {
            path:"",
            element:<Navigate replace to="/chart/list"/>
        }
    ]
}

export default chartRouter;