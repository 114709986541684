import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

const Loading = <div>Loading....</div>
const StatisticsList = lazy(() => import("../pages/statistics/ListPage"))

const statisticsRouter = () => {
    return [
        {
            path: "list",
            element: <Suspense fallback={Loading}><StatisticsList/></Suspense>
        },
        {
            path: "",
            element: <Navigate replace to="list"/>
        }
    ]
}

export default statisticsRouter;