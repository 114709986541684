// import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import todoRouter from "./todoRouter";
import productsRouter from "./productsRouter";
import memberRouter from "./memberRouter";
import boardRouter from "./boardRouter";
import chartRouter from "./chartRouter";
import statisticsRouter from "./statisticsRouter";

const { createBrowserRouter } = require("react-router-dom");

const Loading = <div>Loading...</div>
const Main = lazy(() => import("../pages/MainPage"))
const About = lazy(() => import("../pages/AboutPage"))
const TodoIndex = lazy(() => import("../pages/todo/IndexPage"))
const ProductsIndex = lazy(() => import("../pages/products/IndexPage"))
const BoardIndex = lazy(() => import("../pages/board/IndexPage"))
const ChartIndex = lazy(() => import("../pages/chart/IndexPage"))
const StatisticsIndex = lazy(() => import("../pages/statistics/IndexPage"))

const root = createBrowserRouter([
    {
        path: "",
        element: <Suspense fallback={Loading}><Main/></Suspense>
    },
    {
        path: "about",
        element: <Suspense fallback={Loading}><About/></Suspense>
    },
    {
        path: "todo",
        element: <Suspense fallback={Loading}><TodoIndex/></Suspense>,
        children: todoRouter()
    },
    {
        path: "products",
        element: <Suspense fallback={Loading}><ProductsIndex/></Suspense>,
        children: productsRouter()
    },
    {
        path: "board",
        element: <Suspense fallback={Loading}><BoardIndex/></Suspense>,
        children: boardRouter()
    },
    {
        path: "chart",
        element: <Suspense fallback={Loading}><ChartIndex/></Suspense>,
        children: chartRouter()
    },
    {
        path: "statistics",
        element: <Suspense fallback={Loading}><StatisticsIndex/></Suspense>,
        children: statisticsRouter()
    },
    {
        path: "member",
        children: memberRouter()
    }
])

export default root;